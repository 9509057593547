import React from "react"

/* Components */
import Layout from "../../components/layout"
import ContentTemplate from "../../components/contentTemplate"
import HeaderPara from "../../components/headPara"
import CaptionedImage from "../../components/captionedImage"
import CaptionedEmbed from "../../components/captionedEmbed"
import PageNav from "../../components/pageNav"
import Divider from "../../components/divider"

/* Images */
import mainImage from "../../images/anywhr-lip/hero.png"
import mainImageAlt from "../../images/anywhr-lip/hero-loading.png"
import journeyMap from "../../images/anywhr-lip/design-sprint-journey-map.svg"
import atomicDesign from "../../images/anywhr-lip/atomic-design.png"
import destinationCards from "../../images/anywhr-lip/destination-cards.svg"
import routeTabs from "../../images/anywhr-lip/route-tabs.svg"
import styleguideAdjustments from "../../images/anywhr-lip/styleguide-adjustments.svg"
import futureJourney from "../../images/anywhr-lip/detailed-future-state-journey-map.svg"
import earlySketches from "../../images/anywhr-lip/early-sketches.png"
import shortlistActivitiesWorkFlow from "../../images/anywhr-lip/TC-shortlist-activity-work-flow.svg"
import shortlistActivitiesUI from "../../images/anywhr-lip/activities-user-flow-UI.png"

const AnywhrLIP = () => (
  <Layout>
    <ContentTemplate
      title={"Anywhr's Own Itinerary Planning Tool"}
      subtitle={"Scaling & optimising trip tlanning for all kinds of trips"}
      year={"2020"}
      tags={"Research · UX · UI"}
      mainImage={mainImage}
      mainImageAlt={mainImageAlt}
      responsibilities={
        "Design system, Itinerary Planning flows, and leading interaction design"
      }
      team={"Product Interns - Li Wanying, Andy Chan, Audrina Aziz"}
      duration={
        "5 months (concurrent with Itinerary Display on consumer web app)"
      }
    >
      <HeaderPara>
        <p className="subtitle">
          {
            "The Itinerary Planner is one of the main features of Anywhr's existing internal operations platform, Llamafarm. It aids Trip Curators in deciding destinations, accommodations, activities, and transportation. The resulting itinerary is presented on Anywhr's consumer-facing web app."
          }
        </p>
      </HeaderPara>
      <HeaderPara header={`Problem`}>
        <p>
          {
            "It was difficult for Trip Curators to handle a larger volume of trip planning requests if the period of time it takes for them to complete one trip takes too long."
          }
        </p>
        <p>
          {
            "Disregarding the number of Trip Curators Anywhr had as a factor, the team decided that maximising the number of itineraries planned in a day while minimising the time it takes to confirm and book a customer's trip is the first step to scale up trip planning operations."
          }
        </p>
      </HeaderPara>
      <CaptionedImage
        imageSrc={journeyMap}
        caption={
          "Existing customer journey: most concerns revolved around how quickly a trip can be planned and confirmed with the traveller"
        }
      />
      <HeaderPara
        header={`Why is it time-consuming for Trip Curators when they plan trips?`}
      >
        <p>
          {
            "As Anywhr believes in designing unique travel journeys, each travel itinerary is planned based on the customers' travel interests and preferences which was highly time-consuming to prepare. Multiple tools are used to present information about the itinerary to the customer, such as a document for the itinerary content and a spreadsheet for the cost breakdown."
          }
        </p>
        <br />
        <p>
          {
            "Trip Curators find that the more detailed an itinerary is, the more time and effort it takes to amend itineraries, hence they choose to build up the itinerary part by part and get confirmation from the customer on destinations and accommodations first, before moving on to activities and transport. This process results in a much longer comunication loop as both the Trip Curator and the customer need to wait for each other to reply."
          }
        </p>
      </HeaderPara>
      <Divider />
      <HeaderPara header={`Goals`}>
        <p>
          {
            "This feature aims to become a solution that helps address these needs:"
          }
        </p>
        <ol>
          <li>
            <b>
              {
                "Facilitate the exchange between the customer and Trip Curator after the trip request is submitted."
              }
            </b>
            {
              "To minimise the time needed for the feedback and communication loop, which was identified as the bottleneck in lead conversion."
            }
          </li>
          <li>
            <b>
              {
                "Reduce the time and effort taken to put together an attractive itinerary for the customer."
              }
            </b>
            {
              "Trip Curators place importance on making the itinerary look visually appealling as it helps the customer to place trust in the service which they are paying a premium for."
            }
          </li>
        </ol>
        <p>
          {
            "As the content types within the itinerary are still evolving through trial and error, the challenge lies in designing modularly as we anticipate that the needs of the Trip Curators will change according to the itinerary."
          }
        </p>
      </HeaderPara>
      <HeaderPara header={`Approach`}>
        <p>
          <b>{"Finding out the common pattern of trip planning"}</b>
        </p>
        <p>
          {
            "There was a general pattern to how Trip Curators planned trips, which consists of 3 main phases:"
          }
        </p>
        <p>
          {
            "With this and the assumption that itinerary content no longer need to be manually written up, the overall journey of how trip planning could be was drawn up and agreed upon with Trip Curators as such:"
          }
        </p>

        {/* <p>
          <b>
            {
              "What parts of the existing process of trip planning automatable now and later?"
            }
          </b>
        </p>
        <p>
          {
            "Discussions with the Engineering team helped scope the development phases based on the data and engineering work required to set project milestones and deliverables."
          }
        </p> */}
      </HeaderPara>
      <CaptionedImage
        imageSrc={futureJourney}
        caption={"Future-state journey"}
      />
      <HeaderPara>
        <p>
          {
            "To start, I decided to set the screen layout and overall look of the design system so that different user flows and pages can be designed simultaneously. We studied various websites and apps that had similar features to get a feeling about interactions and layouts people are familiar with and started sketching ideas on the layout and functions. We rapidly prototyped our ideas to test the usability of the layout with Trip Curators and confirmed the layout after a few rounds of testing."
          }
        </p>
      </HeaderPara>
      <CaptionedImage
        imageSrc={earlySketches}
        caption={"Initial sketches & wireframes"}
      />
      <CaptionedEmbed
        caption={
          "Early prototype to test the viability of screen layout & functionality"
        }
      >
        <iframe
          title={`Early prototype of Itinerary Planner to test the viability of screen layout & functionality`}
          style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
          width="100%"
          height="600"
          src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FVCVGXt9hlGCnZwweTXbzMr%2FAnywhr-2020-04-27-Llamafarm-Itinerary-Planner-1.1-Prototype%3Fnode-id%3D2%253A203%26viewport%3D488%252C194%252C0.09968209266662598%26scaling%3Dmin-zoom"
          allowfullscreen
        ></iframe>
      </CaptionedEmbed>
      <Divider />
      <HeaderPara
        header={`Adapting Consumer-facing Web App's Styleguide to speed up MVP design process`}
      >
        <p>
          {
            "The existing UI style guide developed for the consumer-facing site without consideration for a data-heavy interface. Using the same styles will lead to a lack of screen estate due to the large fonts and white-spaces. I designed a version of the style guide with these constraints to be used for Llamafarm."
          }
        </p>
      </HeaderPara>
      <CaptionedImage
        imageSrc={styleguideAdjustments}
        caption={
          "General guidelines and basic UI components: Some adjustments included fonts that had smaller character widths and displayed well at smaller font sizes, and reduced base spacing units (which reduced paddings and margins)."
        }
      />
      <HeaderPara header={`Creating a Modular Design System`}>
        <p>
          {
            "With the style guide in place, I came up with a basic Design System which made prototyping and designing quicker while ensuring designs from different designers are came together looking like a single whole app. This was important so Developers do not waste time building similar components that look a little different each time."
          }
        </p>
      </HeaderPara>
      <CaptionedImage
        imageSrc={atomicDesign}
        caption={
          "A single card template is used to display destination, accommodation, activity and room information."
        }
      />
      <Divider />
      <HeaderPara
        header={`Designing in Phases According to Segmented User Journey`}
      >
        <p>
          {
            "Having sketched the feature in its entirety before deep diving and figuring out the details,I planned the design timeline according to segmented user task flows such that the team could work in an agile fashion."
          }
        </p>
      </HeaderPara>
      <HeaderPara header={`Further defining features based on user work flows`}>
        <p>
          {
            "How the function work was diagrammed as work flows first to draw up a logical understanding and flow before rapid prototyping to gradually work towards the final UI."
          }
        </p>
      </HeaderPara>
      <CaptionedImage
        imageSrc={shortlistActivitiesWorkFlow}
        caption={
          "Work flow of Trip Curator using the Itinerary Planner to shortlist activities"
        }
      />
      <CaptionedImage
        imageSrc={shortlistActivitiesUI}
        caption={
          "Key screens in user flow of adding activities to the itinerary"
        }
      />
      <HeaderPara>
        <p>
          {
            "Keeping engineers in the loop before hand-off helped define the functionality and behaviour of components as any questions and confusion were raised while design was still work-in-progress."
          }
        </p>
      </HeaderPara>
      <CaptionedImage
        imageSrc={destinationCards}
        caption={"Hand-off of Destination Cards"}
      />
      <CaptionedImage imageSrc={routeTabs} caption={"Hand-off of Route Tabs"} />
      <Divider />
      <HeaderPara header={`Learnings`}>
        <p>
          {
            "Importance of defining terms used to refer to parts of the interface and itinerary - without this, it can get pretty confusing very quickly. Appropriate terminology should be used to discern betwwen similar but different components and functionalities so that we could communicate effectively across the entire team."
          }
        </p>
      </HeaderPara>
      <PageNav
        prevTitle={"Anywhr Brand Refresh"}
        prevPath={"/2020/anywhr-brand-refresh"}
      />
    </ContentTemplate>
  </Layout>
)

export default AnywhrLIP
