import React from "react"
import PropTypes from "prop-types"
import { Flex, Box } from "rebass"

const CaptionedEmbed = ({
  children,
  caption,
  style: propStyles,
  fullWidth = true, //by default, fills parent container's width
}) => (
  <Flex
    p={[1, 2, 3]}
    my={[1, 2, 3]}
    style={{
      background: "#0000000a",
      width: fullWidth ? "auto" : "fit-content",
      ...propStyles,
    }}
    flexDirection="column"
    alignItems="center"
  >
    <Box mb={4} textAlign="center" maxWidth="600px">
      <span className="cu" fontSize={[0, 1, 1]}>
        {caption}
      </span>
    </Box>
    {children}
  </Flex>
)

CaptionedEmbed.propTypes = {
  children: PropTypes.node.isRequired,
  caption: PropTypes.string,
  style: PropTypes.object,
  fullWidth: PropTypes.bool,
}

export default CaptionedEmbed
